<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/offline/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.storeTickets.title') }}
        </ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button
          :router-link="`/offline/stores/${this.$route.params.id}/tickets/add`"
        >
          <ion-icon :icon="icons.add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <!-- Pending tickets -->
      <ion-card
        v-for="(ticket, index) in pendingTickets"
        :key="index.id"
        style="opacity: 0.4"
      >
        <ion-card-header>
          <ion-card-title>
            {{ formatDate(ticket.created) }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          Pending...
        </ion-card-content>
      </ion-card>
      <!-- Local tickets -->
      <ion-card
        v-for="ticket in tickets"
        :key="ticket.id"
        @click="onSelect(ticket)"
        button
      >
        <ion-card-header>
          <ion-card-subtitle v-if="ticket.project">
            {{ ticket.project.name }} - {{ ticket.project.customer.name }}
          </ion-card-subtitle>
          <ion-card-title>
            {{ formatDate(ticket.created) }} -
            {{ ticket.scenario.name }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ODNBadges>
            <ODNBadge :color="ticket.ticketStatus.color">
              {{ $t(`ticketStatuses.${ticket.ticketStatus.name}`) }}
            </ODNBadge>
            <ODNBadge>
              <ion-icon :icon="icons.ellipseOutline" />
              {{ $t(`ticketOrigins.${ticket.ticketOrigin.name}`) }}
            </ODNBadge>
          </ODNBadges>
          <p>{{ ticket.contactName }}</p>
          <p>{{ ticket.contactPhone }}</p>
          <p>{{ ticket.contactEmail }}</p>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="tickets.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="parseMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { Filesystem, Directory } from '@capacitor/filesystem';

import { Base64 } from 'js-base64';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  IonBackButton,
  IonFab,
  IonFabButton,
  toastController,
} from '@ionic/vue';
import { ellipseOutline, add } from 'ionicons/icons';

import ODNNoContent from '@c/odn-no-content.vue';
import ODNBadges from '@c/odn-badges.vue';
import ODNBadge from '@c/odn-badge.vue';

export default {
  name: 'OfflineStoreTickets',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    IonBackButton,
    IonFab,
    IonFabButton,
    ODNNoContent,
    ODNBadges,
    ODNBadge,
  },
  data() {
    return {
      tickets: [],
      ticketList: [],
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      loading: true,
      icons: {
        ellipseOutline,
        add,
      },
    };
  },
  computed: {
    ...mapGetters('offline', ['getSection', 'getSavedItems']),
    pendingTickets() {
      return this.getSavedItems('newTicket').filter(
        (ticket) => ticket.data.store.id === parseInt(this.$route.params.id)
      );
    },
  },
  async ionViewDidEnter() {
    await this.loadLocalData();
    await this.parseData(true);
  },
  ionViewDidLeave() {
    this.tickets = [];
    this.ticketList = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    ...mapActions('tickets', ['selectTicket']),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    async loadLocalData() {
      this.loading = true;
      try {
        const rawData = await Filesystem.readFile({
          path: 'tickets.json',
          directory: Directory.Data,
        });

        this.ticketList = JSON.parse(Base64.decode(rawData.data));
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.tickets.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async parseData(clear = false) {
      if (clear) {
        this.page = 0;
        this.tickets = [];
      }

      this.tickets = this.tickets.concat(
        this.ticketList
          .filter((t) => {
            const storeId = parseInt(this.$route.params.id);
            const search = this.searchText.toLowerCase();
            return (
              t.store.id === storeId &&
              t.scenario.name.toLowerCase().indexOf(search) > -1
            );
          })
          .slice(this.page * this.limit, this.page * this.limit + this.limit)
      );

      if (this.tickets.length < this.ticketList.length) {
        this.disableInfiniteScrolling = false;
      } else {
        this.disableInfiniteScrolling = true;
      }
    },
    async parseMoreData(event) {
      this.page++;
      await this.parseData();
      event.target.complete();
    },
    async onSearch() {
      await this.parseData(true);
    },
    async onRefresh(event) {
      await this.parseData(true);
      event.target.complete();
    },
    onSelect(ticket) {
      this.selectTicket(ticket);
      this.$router.push(
        `/offline/stores/${this.$route.params.id}/tickets/${ticket.id}`
      );
    },
  },
};
</script>
